import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import useStoryblok from "../lib/storyblok";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../components/dynamicComponent";
import Link from "../utils/link";

const NotFoundPage = ({ location }) => {
  let components = null;
  let story = useStoryblok(null, location);

  if (story) {
    components = story.content.blocks.map((blok) => {
      return <DynamicComponent blok={blok} key={blok._uid} />;
    });
  }

  return (
    <Layout {...sbEditable(story ? story.content : false)}>
      <SEO title="404: Not found" />
      {components}

      {components == null ? (
        <div className="flex flex-col justify-center items-center space-y-2 h-screen bg-theme-secondary text-white bg-black">
          <h1 className="text-8xl font-bold">404</h1>
          <h2 className="text-2xl font-bold">Page not found</h2>
          <div className="pt-8">
            <Link to="/" className="btn">
              Home
            </Link>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default NotFoundPage;
